import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DialogRef, LegacyDialogModule, LegacyDialogService, LegacyDialogSize, legacyDialogSize } from '@sb/ui';
import { clsx } from 'clsx';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'rModal, sb-route-dialog',
  template: `
    <ng-template #template>
      <ng-content></ng-content>
    </ng-template>
  `,
  standalone: true,
  imports: [LegacyDialogModule],
})
export class RouteModalComponent implements AfterViewInit, OnDestroy {
  @Input()
  public size: legacyDialogSize = 'medium';
  @Input()
  public set width(value: number | undefined) {
    this._width = value;
    if (this.dialog) {
      this.dialog.updateSize(`${this.width}px`);
    }
  }
  public get width() {
    return this._width;
  }
  private _width: number | undefined = undefined;
  @Input()
  public set hidden(value: boolean) {
    if (this.hidden === value) {
      return;
    }
    this._hidden = value;
    if (this.dialog) {
      this.toggleHiddenClass();
    }
  }
  public get hidden(): boolean {
    return this._hidden;
  }
  private _hidden = false;

  @ViewChild('template', { static: true })
  public template;

  private dialog: DialogRef;

  // prevents sidebar closing when clicking on modal backdrop
  private readonly dontCloseSidebarClass = 'js-dont-close-sidebar';

  private destroyed$ = new Subject<void>();

  public constructor(
    private router: Router,
    private dialogService: LegacyDialogService,
    private element: ElementRef,
  ) {}

  public ngAfterViewInit(): void {
    this.dialog = this.dialogService.open(this.template, {
      width: this.width ? `${this.width}px` : LegacyDialogSize[this.size],
      autoFocus: false,
      // pass class names to dialog panel for sizes and other styles to work
      panelClass: [this.dontCloseSidebarClass, ...this.element.nativeElement.className.split(' ')],
    });
    this.toggleHiddenClass();
    void this.dialog.closed.pipe(takeUntil(this.destroyed$)).subscribe((restoreOutletState = true) => {
      if (restoreOutletState) {
        this.router.navigate(['', { outlets: { modal: null } }]);
      }
    });
  }

  public ngOnDestroy(): void {
    this.dialog?.close(false);
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private toggleHiddenClass() {
    if (this.hidden) {
      this.dialog.addPanelClass(clsx('hidden'));
      return;
    }
    this.dialog.removePanelClass(clsx('hidden'));
  }

  public close() {
    this.dialog?.close();
  }
}
